import { DatePicker } from 'antd';
import * as eva from 'eva-icons';
import moment from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import React, { useEffect } from 'react';
import { defaultDateFormat } from '../common/constants';

const MyDatePicker = DatePicker.generatePicker(momentGenerateConfig);
const { RangePicker } = MyDatePicker;
const CustomRangePicker = ({ setDateSelected, className }) => {
  const disabledDate = (current) => {
    // Can not select future date
    return current && current > moment().endOf('day');
  };

  useEffect(() => {
    eva.replace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rangePresets = [
    {
      label: 'Yesterday',
      value: [moment().subtract(1, 'days'), moment()],
    },
    {
      label: 'Last 8 Days',
      value: [moment().subtract(7, 'days'), moment()],
    },
    {
      label: 'Last 31 Days',
      value: [moment().subtract(30, 'days'), moment()],
    },
    {
      label: 'Last 3 Months',
      value: [moment().subtract(3, 'months'), moment()],
    },
  ];

  return (
    <RangePicker
      className={`custom-date-picker ${className}`}
      disabledDate={disabledDate}
      separator="-"
      popupStyle={{ top: 30 }}
      suffixIcon={<i data-eva="calendar-outline" />}
      format={defaultDateFormat}
      placeholder={['Start Date', 'End Date']}
      renderExtraFooter={() => (
        <div className="text-danger">
          All data is based on Greenwich Mean Time (GMT)
        </div>
      )}
      getPopupContainer={(triggerNode) => triggerNode?.parentElement}
      popupClassName="custom-date-picker-panel"
      presets={rangePresets}
      onChange={(data, dates) => {
        if (data) {
          setDateSelected([
            moment(dates?.[0], defaultDateFormat),
            moment(dates?.[1], defaultDateFormat),
          ]);
        } else {
          setDateSelected('');
        }
      }}
      placement="bottom"
    />
  );
};

export default CustomRangePicker;
