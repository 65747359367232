import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Tag } from 'antd';
import { forEach, includes } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import { Active, EditButton, Inactive } from '../../../../../assets/svg';
import {
  ALL_ROLES_KEYS,
  BREAKPOINTS,
  SCROLL_CONST,
} from '../../../../../common/constants';
import { titleCase } from '../../../../../common/utils';
import CommonCard from '../../../../../components/CommonCard';
import CommonTable from '../../../../../components/CommonTable';
import SearchComponent from '../../../../../components/SearchComponent';
import EllipsisText from '../../../../users/components/EllipsisText';
import AddEditUserModal from '../../../AddEditUserModal';
import DeleteModal from '../../../DeleteModal';
import { UPDATE_STATUS } from '../../../graphql/Mutations';
import { GET_ORGANIZATION_USERS } from '../../../graphql/Queries';

const Users = ({ data: organizationData }) => {
  const [showModal, setShowModal] = useState(false);
  const [showStatusModal, setStatusModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [mutationId, setMutationId] = useState('');
  const [title, setTitle] = useState('');
  const [scrollFlag, setScrollFlag] = useState(false);
  const [userData, setUserData] = useState(null);
  const [newdata, setNewdata] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [sortedInfo, setSortedInfo] = useState({});

  const initialUserFilter = {
    skip: 0,
    limit: 10,
    search: '',
    sortBy: { field: 'updatedAt', order: 'DESC' },
  };
  const initialPaginationValue = {
    total: 0,
    current: 1,
  };
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [userFilter, setUserFilter] = useState(initialUserFilter);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const { id } = useParams();

  const [updateStatus] = useMutation(UPDATE_STATUS, {
    onError() { },
  });
  const handleActiveStatus = () => {
    setStatusModal(true);
  };

  const ActiveButtons = ({ record }) => {
    if (record?.tenantUser?.isActive) {
      return (
        <Inactive
          onClick={() => {
            setMutationId(record?.id);
            setIsDisabled(true);
            handleActiveStatus();
            setTitle('Deactivate');
          }}
        />
      );
    }
    return (
      <Active
        onClick={() => {
          setMutationId(record?.id);
          setIsDisabled(true);
          handleActiveStatus();
          setTitle('Activate');
        }}
      />
    );
  };
  const ChangeStatus = ({ record }) => {
    if (record?.isDisabled === false) {
      return <Tag className="active-tag">Active</Tag>;
    }

    return <Tag className="inactive-tag">Inactive</Tag>;
  };

  const [fetchUsers, { loading }] = useLazyQuery(GET_ORGANIZATION_USERS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: 10,
        total: res?.tenantUserList?.total,
      };
      setPaginationProp(pagination);
      setHasMore(!!res?.tenantUserList?.data?.length);
      if (scrollFlag) {
        const datalist = [...newdata];
        forEach(res?.tenantUserList?.data, (item) => datalist.push(item));
        setNewdata(datalist);
        setScrollFlag(false);
      } else {
        const datalist = [];
        forEach(res?.tenantUserList?.data, (item) => datalist.push(item));
        setNewdata(datalist);
      }
    },
    onError() { },
  });

  useEffect(() => {
    fetchUsers({
      variables: {
        filter: {
          ...userFilter,
          tenantId: Number(id),
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (scrollFlag) {
      fetchUsers({
        variables: {
          filter: {
            ...userFilter,
            skip: newdata?.length,
            tenantId: Number(id),
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollFlag]);

  const handleAddEditUser = (record) => {
    if (record) {
      setUserData(record);
    } else {
      setUserData();
    }
    setShowModal(true);
  };
  const handleScroll = (e) => {
    const { target } = e;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};
    const scrolledToBottom =
      scrollTop + offsetHeight >= scrollHeight - SCROLL_CONST;

    if (scrolledToBottom && hasMore && !loading && !scrollFlag) {
      setScrollFlag(true);
      fetchUsers({
        variables: {
          filter: {
            ...userFilter,
            tenantId: Number(id),
            skip: newdata?.length,
          },
        },
      });
    }
  };
  const handleTableChange = (pagination, paginationFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setPaginationProp({ ...paginationProp, ...pagination });
    setSortedInfo(sorter);
    if (sorter?.column) {
      setUserFilter({
        ...userFilter,
        limit: pagination?.pageSize,
        skip,
        sortBy: {
          field: sorter?.columnKey,
          order: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
        },
      });
      fetchUsers({
        variables: {
          filter: {
            ...userFilter,
            tenantId: Number(id),
            skip,
            limit: pagination?.pageSize,
            sortBy: {
              field: sorter?.columnKey,
              order: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
            },
          },
        },
      });
    } else {
      setUserFilter({
        ...userFilter,
        limit: pagination?.pageSize,
        skip,
        sortBy: { field: 'updatedAt', order: 'DESC' },
      });
      fetchUsers({
        variables: {
          filter: {
            ...userFilter,
            tenantId: Number(id),
            skip,
            limit: pagination?.pageSize,
            sortBy: { field: 'updatedAt', order: 'DESC' },
          },
        },
      });
    }
  };
  const columns = [
    {
      title: 'ID',
      key: 'id',
      render: (text, record, index) => {
        return <div>{userFilter?.skip + index + 1}</div>;
      },
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      width: '25%',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      render: (text) => (
        <>
          <EllipsisText text={text} />
        </>
      ),
    },
    {
      title: 'ROLE',
      dataIndex: 'roles',
      key: 'roles',
      render: (text) => titleCase(text),
    },
    {
      title: 'EMAIL ID',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'email' && sortedInfo?.order,
    },
    {
      title: 'STATUS',
      dataIndex: '',
      key: 'isDisabled',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'isDisabled' && sortedInfo?.order,
      render: (status, record) => {
        if (record?.tenantUser?.isActive) {
          return <Tag className="active-tag">Active</Tag>;
        }
        return <Tag className="inactive-tag">Inactive</Tag>;
      },
    },
    {
      title: '',
      key: 'Action',
      width: '15%',
      align: 'right',
      render: (_, record) => {
        return (
          <div className="action" key={record?.id}>
            <Button
              shape="round"
              icon={<EditButton />}
              disabled={includes(
                [
                  ALL_ROLES_KEYS?.EXTERNAL_AUDITOR,
                  ALL_ROLES_KEYS?.CHECKLIST_MAKER,
                ],
                record?.roles?.[0],
              )}
              className={
                includes(
                  [
                    ALL_ROLES_KEYS?.EXTERNAL_AUDITOR,
                    ALL_ROLES_KEYS?.CHECKLIST_MAKER,
                  ],
                  record?.roles?.[0],
                ) && 'disabled-button'
              }
              onClick={() => handleAddEditUser(record)}
            />
            <Button shape="round" icon={<ActiveButtons record={record} />} />
          </div>
        );
      },
    },
  ];

  const getData = async (value) => {
    setUserFilter({ ...userFilter, search: value, skip: 0 });
    setPaginationProp({ ...paginationProp, current: 1 });
    fetchUsers({
      variables: {
        filter: {
          ...userFilter,
          skip: 0,
          tenantId: Number(id),
          search: value,
        },
      },
    });
  };

  return (
    <div className="user-details">
      {showModal && (
        <AddEditUserModal
          showModal={showModal}
          setShowModal={setShowModal}
          userData={userData}
          organizationData={organizationData}
          isUpdate={!!userData}
          fetchUsers={() => {
            setPaginationProp(initialPaginationValue);
            fetchUsers({
              variables: {
                filter: {
                  ...userFilter,
                  tenantId: Number(id),
                },
              },
            });
          }}
        />
      )}
      {showStatusModal && (
        <DeleteModal
          showModal={showStatusModal}
          setShowModal={setStatusModal}
          title={title}
          updateUserStatus={updateStatus}
          mutationId={mutationId}
          setMutationId={setMutationId}
          isDisabled={isDisabled}
          fetchUserData={() => {
            fetchUsers({
              variables: {
                filter: {
                  ...userFilter,
                  tenantId: Number(id),
                },
              },
            });
          }}
          id={id}
          name="User"
          subtitle="User"
        />
      )}
      <div className="users-header">
        <h1 className="users-header-title">Tenant Users</h1>
        <div className="d-flex">
          <SearchComponent
            id="search-container-id"
            name="Users"
            getData={getData}
          />
          <Button
            type="primary"
            shape="round"
            className="ml-5"
            onClick={() => handleAddEditUser()}
          >
            {isDesktopViewport ? 'Add User' : 'Add'}
          </Button>
        </div>
      </div>
      {isDesktopViewport ? (
        <div className="user-table pointer">
          <CommonTable
            loadingData={loading}
            columns={columns}
            data={newdata}
            rowKey={(obj) => obj?.id}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
          />
        </div>
      ) : (
        <div className="users-card pointer" onScroll={handleScroll}>
          {newdata?.map((data) => {
            return (
              <div key={data?.id}>
                <CommonCard>
                  <div>
                    <div className="card-header">
                      <h1 className="card-header-h1">{data?.name}</h1>
                      <span>
                        <ChangeStatus record={data} />
                      </span>
                    </div>
                    <div className="card-content">
                      <br />
                      <p>{titleCase(data?.roles)}</p>
                      <div className="d-flex justify-between align-center">
                        <span className="mr-5 word-break">
                          <EllipsisText
                            className="text-secondary"
                            text={data?.email}
                          />
                        </span>
                        <span className="action d-flex">
                          <Button
                            shape="round"
                            icon={<EditButton />}
                            onClick={() => handleAddEditUser(data)}
                          />
                          <Button
                            shape="round"
                            icon={<ActiveButtons record={data} />}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </CommonCard>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Users;
