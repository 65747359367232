import { Button, Layout, Menu, Popover, Tag } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { useMedia } from 'react-use';
import { InfoIconDark } from '../../../assets/svg';
import { LoginLogo } from '../../../assets/svg/login';
import { Account } from '../../../assets/svg/sider';
import { BREAKPOINTS, MODULES, ROUTES } from '../../../common/constants';
import useRouter from '../../../hooks/useRouter';

const { Header } = Layout;

const AppHeader = () => {
  const {
    location: { pathname },
  } = useRouter();
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS?.desktop}px)`);

  const subMenu = [
    {
      label: 'Account',
      key: 'SubMenu',
      icon: <Account />,
      children: [
        {
          key: ROUTES.CHANGE,
          label: (
            <Link to={ROUTES.CHANGE} className="d-flex align-center">
              Change Password
            </Link>
          ),
        },
        {
          key: ROUTES.LOGOUT,
          label: (
            <Link to={ROUTES.LOGOUT} className="d-flex align-center">
              Logout
            </Link>
          ),
        },
      ],
    },
  ];

  const menus = [
    {
      label: (
        <Link to={ROUTES.ORGANIZATION} className="d-flex align-center">
          {MODULES.ORGANIZATIONS}
        </Link>
      ),
      key: ROUTES.ORGANIZATION,
    },
    {
      label: (
        <Link to={ROUTES.USERS} className="d-flex align-center">
          {MODULES.USERS}
        </Link>
      ),
      key: ROUTES.USERS,
    },
    {
      label: (
        <Link to={ROUTES.GALLERY} className="d-flex align-center">
          <div className="d-flex align-center gallery-heading">
            {MODULES.GALLERY}
            <Tag className="tag-beta ml-5" color="#202A3F">
              BETA
            </Tag>
            <Popover
              content={
                <>
                  This is beta feature and may have bug and errors. This feature
                  can be stopped without any prior notice.
                </>
              }
              placement="right"
              overlayClassName="gallery-info"
            >
              <Button className="p-0">
                <InfoIconDark height="18px" width="18px" />
              </Button>
            </Popover>
          </div>
        </Link>
      ),
      key: ROUTES.GALLERY,
    },
  ];

  return (
    <>
      {isDesktopViewport && (
        <Header>
          <div className="gx-search-bar gx-d-none gx-d-lg-block gx-lt-icon-search-bar-lg d-flex width-percent-100">
            <div className="header-logo">
              <Link to={ROUTES.MAIN} className="gx-site-logo mr-25">
                <LoginLogo />
              </Link>
            </div>
            <div className="header custom-header d-flex align-center justify-center width-percent-100">
              <Menu
                selectedKeys={[`/${pathname?.split('/')[1]}`]}
                mode="horizontal"
                items={menus}
              />
            </div>
          </div>
          <div className="header-right">
            <Menu
              selectedKeys={[`/${pathname?.split('/')[1]}`]}
              mode="horizontal"
              items={subMenu}
            />
          </div>
        </Header>
      )}
      <Header className={`title-bar ${isDesktopViewport ? 'mt-15' : ''}`}>
        <div className="header-portal-wrapper d-flex justify-between align-center fill-width">
          <div id="header-left-content" className="d-flex align-center">
            <div id="header-left-content-inside" className="d-flex" />
          </div>
          <div className="d-flex justify-between buttons">
            <div id="add-search-organization" />
            <div id="header-right-content" />
          </div>
        </div>
      </Header>
    </>
  );
};
export default AppHeader;
